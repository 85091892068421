@privacy: privacy;
.@{privacy} {
  &-wrapper {
    padding: 48px 80px 48px 80px;
  }

}

@media screen and (max-width: 768px) {
  .@{privacy} {
    padding: 0 48px;
    &-wrapper {
      padding: 48px 18px 48px 18px;
    }
  }
}
@primary-color: #1DA57A;