body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.header3 {
  background: #fff;
  width: 100%;
  z-index: 1;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  position: relative;
  top: 0;
}
.header3 .home-page {
  padding: 0 24px;
}
.header3-logo {
  display: inline-block;
  position: relative;
  width: 150px;
  line-height: 64px;
}
.header3-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header3-logo a {
  display: block;
}
.header3-menu {
  float: right;
}
.header3-menu .ant-menu-horizontal {
  border-bottom: none;
}
.header3-menu .ant-menu {
  line-height: 62px;
  height: 64px;
  background: transparent;
}
.header3-menu .ant-menu a {
  display: block;
}
.header3-item-block {
  padding: 0 8px;
}
.header3-item-block > * {
  display: inline-block;
}
.header3-item .ant-menu-sub .ant-menu-item,
.header3-item-child .ant-menu-sub .ant-menu-item,
.header3-menu .ant-menu-sub .ant-menu-item,
.header3-item .ant-menu-inline .ant-menu-item,
.header3-item-child .ant-menu-inline .ant-menu-item,
.header3-menu .ant-menu-inline .ant-menu-item {
  height: auto;
  line-height: 1.5;
}
.header3-item .item-sub-item,
.header3-item-child .item-sub-item,
.header3-menu .item-sub-item {
  display: block;
  padding: 8px 24px;
}
.header3-item .item-image,
.header3-item-child .item-image,
.header3-menu .item-image {
  float: left;
  margin-right: 16px;
  margin-top: 4px;
  position: relative;
  z-index: 1;
}
.header3-item .item-title,
.header3-item-child .item-title,
.header3-menu .item-title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  margin-left: 46px;
}
.header3-item .item-content,
.header3-item-child .item-content,
.header3-menu .item-content {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.75);
  margin-left: 46px;
}
@media screen and (max-width: 767px) {
  .header3-logo {
    z-index: 101;
  }
  .header3.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header3-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    background: #fff;
  }
  .header3-menu li {
    padding: 0 24px;
  }
  .header3-menu li.ant-menu-submenu {
    padding: 0;
  }
  .header3-menu .item-sub-item {
    padding: 8px 0;
  }
  .header3-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header3-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #001529;
    margin-top: 4px;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header3-mobile-menu :first-child {
    margin-top: 0;
  }
  .header3 .ant-menu {
    height: auto;
    overflow: hidden;
  }
  .header3 .ant-menu .ant-menu-item-selected {
    border: none;
  }
  .header3 .open {
    height: auto;
  }
  .header3 .open .header3-mobile-menu em:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }
  .header3 .open .header3-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header3 .open .header3-mobile-menu em:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
  }
  .header3 .open > .header3-menu {
    opacity: 1;
    pointer-events: auto;
  }
  .header3-item-block {
    height: 40px;
    line-height: 40px;
  }
}
.banner5 {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #fdfdfd;
  background-image: linear-gradient(to top, rgba(253, 253, 253, 0.3) 0%, rgba(193, 218, 255, 0.3) 100%);
  overflow: hidden;
}
.banner5 .banner5-page {
  padding: 1px 0 0;
}
.banner5-title {
  font-size: 48px;
  line-height: 1.5;
  color: #5f9bf1;
  margin-bottom: 8px;
}
.banner5-title-wrapper {
  width: 40%;
  max-width: 450px;
  position: relative;
  top: 30%;
  left: 24px;
  font-family: 'PingFang SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.banner5-explain {
  color: #333;
  font-size: 24px;
  margin-bottom: 24px;
}
.banner5-content {
  font-size: 18px;
  color: #666;
  margin-bottom: 48px;
}
.banner5-button-wrapper .banner5-button {
  background: #EA1A1A;
  box-shadow: 0 8px 24px rgba(234, 26, 26, 0.35);
  border-radius: 20px;
  border: none;
  padding: 0 42px;
  line-height: 40px;
  height: 40px;
  transition: transform 0.3s, box-shadow 0.3s;
  margin-bottom: 9px;
}
.banner5-button-wrapper .banner5-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 13px 24px rgba(234, 26, 26, 0.45);
  background: #EA1A1A;
}
.banner5-image {
  overflow: hidden;
  width: 55%;
  max-width: 710px;
  position: absolute;
  top: 15%;
  right: 24px;
}
@media screen and (max-width: 767px) {
  .banner5 {
    min-height: 500px;
  }
  .banner5 .banner5-page.home-page {
    padding-top: 1px;
    padding-bottom: 0;
  }
  .banner5-title-wrapper {
    text-align: center;
    position: static;
    width: 100%;
    margin-top: 64px;
    left: 0;
    top: 0;
  }
  .banner5-title {
    font-size: 32px;
  }
  .banner5-explain {
    font-size: 18px;
  }
  .banner5-content {
    font-size: 14px;
  }
  .banner5-image {
    position: static;
    width: 100%;
    top: 0;
    right: 0;
    margin: 32px 0 24px;
  }
}
.content0-wrapper {
  min-height: 446px;
  overflow: hidden;
}
.content0-wrapper .content0 {
  height: 100%;
  padding: 64px 24px;
}
.content0-wrapper .content0 > .title-wrapper {
  margin: 0 auto 48px;
}
.content0-wrapper .content0 > .title-wrapper .feature-title {
  font-size: 48px;
}
.content0-wrapper .content0 > .title-wrapper .feature-content {
  font-size: 24px;
}
.content0-wrapper .content0-block {
  padding: 0 4%;
  display: inline-block;
  text-align: center;
  min-height: 200px;
  margin-bottom: 24px;
}
.content0-wrapper .content0-block img {
  width: 100%;
}
.content0-wrapper .content0-block-wrapper {
  position: relative;
  height: 100%;
  top: 25%;
  padding: 20px 0;
}
.content0-wrapper .content0-block.queue-anim-leaving {
  position: relative !important;
}
.content0-wrapper .content0-block-icon {
  width: 100px;
  height: 100px;
  margin: auto;
}
.content0-wrapper .content0-block-title {
  line-height: 32px;
  margin: 10px auto;
  font-size: 24px;
}
@media screen and (max-width: 767px) {
  .content0-wrapper {
    min-height: 880px;
  }
}
.footer2-wrapper {
  background-color: #fff;
  height: 80px;
  overflow: hidden;
  border-top: 1px solid #d9d9d9;
}
.footer2-wrapper .footer2 {
  height: 100%;
  padding: 0 24px;
  line-height: 80px;
  text-align: center;
  position: relative;
}
.footer2-wrapper .copyright {
  float: right;
}
.footer2-wrapper .copyright > * {
  display: inline-block;
}
.footer2-wrapper .copyright-line {
  padding: 0 12px;
  margin: 0 12px;
}
.footer2-wrapper .copyright-line img {
  background-color: #000;
}
.footer2-wrapper .copyright-links {
  padding-inline: 9px;
}
.footer2-wrapper .links {
  float: left;
  display: flex;
  align-items: center;
  height: 100%;
}
.footer2-wrapper .links a {
  height: 21px;
  display: inline-block;
  margin-right: 32px;
}
.footer2-wrapper .links a img {
  display: block;
}
@media screen and (max-width: 767px) {
  .footer2-wrapper .footer2 {
    font-size: 12px;
  }
  .footer2-wrapper .footer2.home-page {
    padding: 0;
  }
  .footer2-wrapper .footer2 > div {
    width: 90%;
    margin: auto;
  }
}
#Banner5_0 .home-page > .banner5-title-wrapper > .kygodx8mk5u-editor_css {
  color: #222222;
}
#Banner5_0 .banner5-title-wrapper > div > .kygp6k1rnk-editor_css {
  line-height: 40px;
}
#Banner5_0 .home-page > .banner5-title-wrapper > .kygp6ticgq-editor_css {
  height: 40px;
}
#Banner5_0 .banner5-title-wrapper > .kygp6ticgq-editor_css > .kygp6k1rnk-editor_css {
  height: 40px;
  border-width: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
#Feature0_0 .home-page > .title-wrapper > .kygpaxn187-editor_css {
  border-width: 0;
}
#Banner5_0 .home-page > .kygo0ykktmm-editor_css {
  background-clip: padding-box;
}
#Banner5_0.kygoe9had4-editor_css {
  background-image: linear-gradient(315deg, #f5938f 0%, #f7f17b 100%);
  background-clip: padding-box, padding-box;
}
#Footer2_0.kygvq9r3i1-editor_css {
  background-color: #ffffff;
  border-top-color: initial;
  border-top-width: 0;
}
