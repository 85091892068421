#Banner5_0 .home-page > .kygoe605bn9-editor_css {
}
#Banner5_0 .home-page > .banner5-title-wrapper > .kygodx8mk5u-editor_css {
  color: #222222;
}
#Banner5_0 .banner5-title-wrapper > div > .kygp6k1rnk-editor_css {
  line-height: 40px;
}
#Banner5_0 .home-page > .banner5-title-wrapper > .kygp6ticgq-editor_css {
  height: 40px;
}
#Banner5_0
  .banner5-title-wrapper
  > .kygp6ticgq-editor_css
  > .kygp6k1rnk-editor_css {
  height: 40px;
  border-width: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
#Feature0_0 .home-page > .title-wrapper > .kygpaxn187-editor_css {
  border-width: 0;
}
#Banner5_0 .home-page > .kygo0ykktmm-editor_css {
  background-clip: padding-box;
}
#Banner5_0.kygoe9had4-editor_css {
  background-image: linear-gradient(
    315deg,
    #f5938f 0%,
    rgb(247, 241, 123) 100%
  );
  background-clip: padding-box, padding-box;
}
#Nav3_0.kygvqj8avej-editor_css {
}
#Footer2_0.kygvq9r3i1-editor_css {
  background-color: rgb(255, 255, 255);
  border-top-color: initial;
  border-top-width: 0;
}
